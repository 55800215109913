/* eslint-disable import/prefer-default-export */
export const getCategories = `
{
    categories {
        items {
            uid
            name
            level
            path
            url_path
            url_key
            include_in_menu
            children_count
            children {
                uid
                name
                level
                path
                url_path
                url_key
                include_in_menu
                children_count
                children {
                    uid
                    name
                    level
                    path
                    url_path
                    url_key
                    include_in_menu
                    children_count
                    children {
                        uid
                        name
                        level
                        path
                        url_path
                        url_key
                        include_in_menu
                        children_count
                        children {
                            uid
                            name
                            level
                            path
                            url_path
                            url_key
                            include_in_menu
                        }
                    }
                }
            }
        }
    }
}
`;

export const storeConfig = `
{
    storeConfig {
      allow_guest_checkout
      allow_guests_to_write_product_reviews
      base_currency_code
      base_media_url
      base_static_url
      base_url
      catalog_default_sort_by
      category_url_suffix
      code
      copyright
      default_description
      default_display_currency_code
      default_keywords
      default_title
      header_logo_src
      head_shortcut_icon
      locale
      logo_alt
      logo_height
      logo_width
      payments_configuration
      pwa_checkout_debug_enable
      secure_base_media_url
      secure_base_static_url
      store_name
      timezone
      title_prefix
      title_separator
      title_suffix
      website_id
      weight_unit
      welcome
    }
}  
`;

export const frontendOptions = `
{
    storeConfig {
        pwa {
            primary_color
            secondary_color
            background_color
            error_color
            warning_msg_color
            success_msg_color
            font_color
            default_font
            heading_font
            button_background_color
            button_background_hover_color
            button_disabled_text_color
            button_disabled_background_color
            button_text_color
            button_text_hover_color
            button_border_color
            button_border_hover_color
            link_color
            link_hover_color
            link_font_decoration
            link_font_hover_decoration
        }
    }
}
`;

export const getCmsList = `
{
  storeConfig {
    cms_page  
    category_url_suffix
  }
}
`;
